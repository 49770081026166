<template>  
  <v-container fluid class="text-center">
    <v-row>
      <v-col v-show=" !fullMap ? true : false" :cols="!fullMap? showMap? window.width < 670?12:6 :12 :0" >
        <v-card class="flexcard" height="100%">
          <v-toolbar dense color="secondary" class="white--text">
            <v-btn fab v-if="limpiaSeleccionMapa" dark x-small color="success" @click="cancelSelectedRoute()" class="ml-0" title="limpia selección">
              <v-icon dark>
                mdi-cancel
              </v-icon>
            </v-btn>

            <v-btn fab v-if="getRoutes.length > 0" dark x-small color="info" @click="sendFilteredRoutesToMap()" class="ml-3" title="visualiza rutas en mapa ">
              <v-icon dark>
                mdi-map-marker-check
              </v-icon>
            </v-btn>

            <v-btn fab v-if="routes.length>0" dark x-small color="info" @click="overlay = !overlay" class="ml-3" title="opciones de filtro">
              <v-icon dark>
                mdi-filter
              </v-icon>
            </v-btn>

            <v-btn fab dark x-small color="info" @click="showMap=!showMap" class="ml-3" title="mostrar vista de mapa">
              <v-icon dark>
                mdi-map
              </v-icon>
            </v-btn>

            <v-btn fab dark v-if="routes.length>0" x-small color="info"  @click="displayFormat = (displayFormat=='resumen'?'tabla':'resumen')" class="ml-3" :title="displayFormat=='resumen'?'muestra tabla':'muestra resumen'">
              <v-icon v-if="displayFormat=='resumen'" dark>
                mdi-table-large
              </v-icon>
              <v-icon v-else dark>
                mdi-reorder-horizontal
              </v-icon>              
            </v-btn>

            <v-btn fab v-if="routes.length>0" dark x-small color="info" @click="makeResult()" class="ml-3" title="exporta a csv">
              <v-icon dark>
                mdi-microsoft-excel
              </v-icon>
            </v-btn>

            <v-btn fab dark x-small class="ml-3 mr-3" :color="'info'" title="carga información de abasto">
              <v-file-input style="margin-top: -3px;margin-left: 8px;" hide-input truncate-length="15" dense outlined solo small-chips color="primary" v-model="file.id" show-size label="Seleccionar archivo..." :accept="mime_types" @change="loadFile()" :disabled="loading"></v-file-input>                
            </v-btn>
            
          </v-toolbar>
          <v-card-text>
            <div v-show="displayFormat=='resumen'" :style="'overflow-y:auto;max-height:'+(window.height)+'px;'">
              <div v-if="Object.keys(selectedRoute).length>0" style="width:100%;display: flex;justify-content: center;align-items: center; ">
                <div v-if="selectedRoute.planta != ''" style="max-width:50ch;">
                  <h3 v-if="cb[selectedRoute.idOrigen] && cb[selectedRoute.idDestino]" class="subtitle" >
                    De {{ selectedRoute.idOrigen }} {{ cb[selectedRoute.idOrigen]?cb[selectedRoute.idOrigen].descripcion:selectedRoute.idOrigen }} a {{ selectedRoute.idDestino }} {{ cb[selectedRoute.idDestino]?cb[selectedRoute.idDestino].descripcion:selectedRoute.idDestino }}<div>[{{ selectedRoute.producto }}]</div>
                  </h3>
                  <template v-for="(element,i) in selectedRoute.cadena" >
                    <template v-if="plants[element]">
                      <div class="box planta" :key="'cadena'+i">
                        {{element}} {{ cb[element]? cb[element].descripcion: element }}
                        <div>Producción: ${{ plants[element] ?numberWithCommasND(plants[element].costo):0 }}
                        </div>                              
                      </div>                            
                    </template>
                    <div v-else class="box bodega" :key="'cadena'+i">
                      {{ selectedRoute.cadena[i] }} {{ cb[selectedRoute.cadena[i]]? cb[selectedRoute.cadena[i]].descripcion:selectedRoute.cadena[i] }}
                    </div>                         
                    <div v-if="i < selectedRoute.cadena.length-1" class="box tramo">
                      <v-icon>mdi-arrow-down</v-icon>                            
                        <div v-if="i == selectedRoute.cadena.length-2">{{ selectedRoute.producto }}</div>                            
                        <div v-else>{{ selectedRoute.cadenaProducto[i] }}</div>
                        <div>{{ selectedRoute.cadenaTransporte[i] }}</div>
                      <div>Flete: ${{ numberWithCommasND(Math.round((selectedRoute.cadenaCostoTramo[i])*100)/100) }}</div>
                      <div>Flete Acum: ${{ numberWithCommasND(Math.round((selectedRoute.cadenaAcumCosto[i])*100)/100) }}</div>
                      <div>Volumen: {{ numberWithCommasND(Math.round((selectedRoute.cadenaVolumen[i])*100)/100) }} Tn.</div>
                      <div>Volumen Tramo: {{ numberWithCommasND(Math.round((selectedRoute.cadenaVolumenTramo[i])*100)/100) }} Tn.</div>
                      
                      <v-icon>mdi-arrow-down</v-icon>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div v-show="displayFormat!='resumen'" :style="'overflow-y:auto;max-height:'+(window.height-ajusteVentana)+'px;'">
              <table v-if="this.routes.length > 0 && window.width >=670 ">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Origen</th>
                    <th>Destino</th>
                    <th>Producto</th>
                    <th>Volumen</th>
                    <th>Planta</th>
                    <th title="Costo de Producción">$Prod</th>
                    <th title="Costo Flete por Tonelada Segemento">$TonSeg</th>
                    <th title="Costo Flete por Tonelada Encadenado">$Ton</th>
                    <th title="Costo Flete por Tonelada Kilómetro">$TonKm</th>
                    <th >Distancia</th>
                    <th >Transporte</th>
                    <th >Vehiculo</th>
                    <th>Saltos</th>                       
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in getRoutes" 
                      @click="setSelectedRoute(item,1)" 
                      :class="Object.keys(selectedRoute).length>0 && item.renglon==selectedRoute.renglon?'selected':''"
                  >
                    <td>{{ item.renglon }}</td>
                    <td class="text-left" :title="cb[item.idOrigen]? item.idOrigen+' '+cb[item.idOrigen].descripcion:item.idOrigen">
                      {{ cb[item.idOrigen]?cb[item.idOrigen].descripcion:item.idOrigen }}
                    </td>
                    <td class="text-left" :title="cb[item.idDestino]? item.idDestino+' '+cb[item.idDestino].descripcion:item.idDestino">
                      {{ cb[item.idDestino]?cb[item.idDestino].descripcion:item.idDestino }}
                    </td>
                    <td>{{ item.producto }}</td>
                    <td>{{ item.volumenTramo }}</td>
                    <td :title="cb[item.planta]?item.planta+' '+cb[item.planta].descripcion:''">
                      {{ item.planta != '' && cb[item.planta]? cb[item.planta].descripcion : '' }}
                    </td>
                    <td>{{ numberWithCommasND(item.costoProduccion) }}</td>
                    <td>{{ numberWithCommasND(item.costoTramo) }}</td>
                    <td>{{ numberWithCommasND(item.costoRuta) }}</td>
                    <td>{{ numberWithCommas(item.costoTramoKm) }}</td>
                    <td>{{ numberWithCommasND(item.distancia) }}</td>
                    <td>{{ item.transporteTramo }}</td>
                    <td>{{ item.vehiculoTramo }}</td>
                    <td>{{ item.saltos }}</td>
                  </tr>
                </tbody>
              </table>
              <!-- resolución menor a 670px cambia de formato a movil -->
              <div v-if="this.routes.length > 0 && window.width < 670 ">
                <div style="color:#000;border:1px solid #eee; " v-for="(item,index) in getRoutes" 
                  @click="setSelectedRoute(item, 1)"
                  :class="Object.keys(selectedRoute).length>0 && item.renglon == selectedRoute.renglon?'selected':''"
                >                  
                  <div style="font-weight:500;width:100%;display:flex;align-content:center;text-align:center;">
                    <div style="align-self:center;" class="ml-2 mr-3 text-center">{{item.renglon}}</div>
                    <div style="align-self:center;width:100%;" class="mr-1 text-center" :title="cb[item.idOrigen]? item.idOrigen+' '+cb[item.idOrigen].descripcion:''">
                      {{ cb[item.idOrigen]?cb[item.idOrigen].descripcion : item.idOrigen }}
                    </div>                    
                    <div style="align-self:center;width:100%;" class="ml-1 text-center" :title="cb[item.idDestino]? item.idDestino+' '+cb[item.idDestino].descripcion:''">
                      {{ cb[item.idDestino]?cb[item.idDestino].descripcion : item.idDestino }}
                    </div>                    
                  </div>
                  
                  <div style="align-self:center;" class="mr-0">{{ item.producto }}</div>
                  <div>{{ item.volumen }}Tn.</div>

                  <div :title="item.planta != '' && cb[item.planta]? cb[item.planta].descripcion : ''">
                    {{ item.planta != '' && cb[item.planta]? cb[item.planta].descripcion : '' }} ${{ numberWithCommasND(item.costoProduccion) }}
                  </div>                  
                  <div>{{ item.saltos }} Saltos</div>
                </div>
              </div>

            </div>            
          </v-card-text>
          <v-card-actions v-show="displayFormat!='resumen'">
            <v-col cols=12>
            <div v-if="routes.length > 0" class="footer-line">{{ getSummary.cantidad }} rutas visibles con {{ numberWithCommasND(getSummary.volumen) }} tn. $TnSeg: {{ numberWithCommasND(getSummary.costoTnSeg) }}, $Tn: {{ numberWithCommasND(getSummary.costoTn) }} y $TnProd: {{ numberWithCommasND(getSummary.costoTnProd) }}</div>
            <div class="footer-line footer-end" >
              <div style="align-self:center; max-width: 100%; overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" >
                {{ fileUploaded }}
              </div>
            </div>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>      
      <v-col v-show="fullMap?true:showMap" :cols="fullMap?12: window.width < 670?12:6">
        <div :class="'btn__mode '+(fullMap?'red':'selected')" @click="fullMap=!fullMap"></div>
        <mapa :plantas="marcadoresPlanta" :rutas="rutasABASTO" :abastoNOM="abastoNOM" :entregasNOM="entregasNOM" :poligonosNOM="poligonosNOM" :selectedRoute="selectedRoute" :cb="cb" :vehicle="vehicle"></mapa>
      </v-col>
    </v-row>

    <!-- loading -->
    <v-overlay absolute :value="loading" opacity="0.8">
      <div class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Procesando...</p>
      </div>
    </v-overlay>
    
    <!-- filtros -->
    <v-overlay v-model="overlay" class="align-center justify-center" contained>
      <v-card light width="360" class="pa-3">
        <h3>Filtros</h3>
        <v-card-text style="max-height:350px;overflow-y:auto;">
        <v-container >
          <!-- filtro de origen-->
          <v-row no-gutters>            
            <v-col>
                <v-combobox multiple v-model="idOrigen" :items="itemsList" clearable dense solo label="Origen" class="mt-n1"></v-combobox>
          <!-- filtro de destino-->
                <v-combobox multiple v-model="idDestino" :items="itemsListDestino" clearable dense solo label="Destino" class="mt-n3"></v-combobox>
          <!-- filtro de productos-->
                <v-combobox multiple v-model="producto" :items="itemsListProducto" clearable dense solo label="Producto"  class="mt-n3"></v-combobox>
          <!-- filtro de saltos-->
                <v-text-field v-model="saltos" dense solo label="Número de saltos" clearable class="mt-n3"></v-text-field>
            </v-col>
          </v-row>
          <!-- Filtro de costo tramo -->
          <v-row no-gutters v-show="false">
            <v-col>
              <div>Costo Segmento $MXN/Tn</div>
              <v-range-slider
                v-model="range"
                :max="max"
                :min="min"
                dense
                class="align-center"
              >
                <template v-slot:prepend>
                  <v-text-field
                    :value="range[0]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    dense
                    style="width: 60px"
                    @change="$set(range, 0, $event)"
                  ></v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field
                    :value="range[1]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    dense
                    type="number"
                    style="width: 60px"
                    @change="$set(range, 1, $event)"
                  ></v-text-field>
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
          <!-- Filtro de volumen -->
          <v-row no-gutters v-show="false">
            <v-col>
              <div>Volumen Tn</div>
              <v-range-slider
                v-model="rangeVol"
                :max="maxVol"
                :min="minVol"
                dense
                class="align-center"
              >
                <template v-slot:prepend>
                  <v-text-field
                    :value="rangeVol[0]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    @change="$set(rangeVol, 0, $event)"
                  ></v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field
                    :value="rangeVol[1]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    @change="$set(rangeVol, 1, $event)"
                  ></v-text-field>
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
          <!-- Filtro de $/TonKm -->
          <v-row no-gutters v-show="false">
            <v-col>
              <div>Costo $MXN/TnKm</div>
              <v-range-slider
                v-model="rangeTonKm"
                :max="maxTonKm"
                :min="minTonKm"
                step="0.1"
                dense
                class="align-center"
              >
                <template v-slot:prepend>
                  <v-text-field
                    :value="rangeTonKm[0]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    dense
                    type="number"
                    style="width: 60px"
                    @change="$set(rangeTonKm, 0, $event)"
                  ></v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field
                    :value="rangeTonKm[1]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    dense
                    type="number"
                    style="width: 60px"
                    @change="$set(rangeTonKm, 1, $event)"
                  ></v-text-field>
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
          
        </v-container>
        </v-card-text>
        <v-card-actions>
          <!-- elementos filtrados -->
          <v-row v-if="getRoutes.length > 0" class="selected">
            <div class="footer-line">
              <div>{{ getSummary.cantidad }} rutas visibles</div> 
              <div>Volumen {{ numberWithCommasND(getSummary.volumen) }} tn. </div>
              <div>$TnSeg: {{ numberWithCommasND(getSummary.costoTnSeg) }}, $Tn: {{ numberWithCommasND(getSummary.costoTn) }} y $TnProd: {{ numberWithCommasND(getSummary.costoTnProd) }}</div>
              </div>
          </v-row>
        </v-card-actions>
        <v-btn color="#1565C0" @click="overlay = false" fab dark small absolute top right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn color="success" title="limpia filtros" @click="clearFilters()" class="mr-12" fab dark small absolute top right>
          <v-icon>mdi-filter-off</v-icon>
        </v-btn>
      </v-card>
    </v-overlay>

    <div v-show="success" class="fade-in alertBox green" > {{ msgSuccess }} </div>
    <div v-show="error" class="fade-in alertBox red" > {{ msgError }} </div>

    <!-- <v-row>
      <v-snackbar v-model="success" color="success" max-width="200" :timeout="2500" bottom right>
        {{ msgSuccess }}
      </v-snackbar>
      <v-snackbar v-model="error" color="error" max-width="200" :timeout="2500" bottom right>
        {{ msgError }}
      </v-snackbar>
    </v-row> -->

  </v-container>
</template>

<script>
import _ from 'lodash'
import * as XLSX from 'xlsx';
import * as Papa from 'papaparse'
import downloadjs from 'downloadjs'
import Mapa from '@/pages/Map.vue'
import Bar from '@/components/Charts/Bar'
import Radar from '@/components/Charts/Radar'
import Pie from '@/components/Charts/Pie'
import PolarArea from '@/components/Charts/PolarArea'
import { EventBus } from "../utils/eventBus.js";


export default {
  name: 'prototype',
  components: { Mapa , Bar, Radar, Pie, PolarArea },
  data: () => ({
    min: -20000,
    max: 20000,
    range: [-20000, 20000],
    minVol: -1500000,
    maxVol: 1500000,
    rangeVol: [-1500000, 1500000],
    minTonKm: -1000,
    maxTonKm: 1000,
    rangeTonKm: [-1000, 1000],
    limpiaSeleccionMapa: false,
    selectedKpi: '',
    ajusteVentana: 85,
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    mime_types: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ],
    file: {
      id: [],
      data: null
    },
    plants: {},    // Plant catalog
    routes: [],    // Routes
    cb:{},         // Benefit cost id relation
    cost:{},       // Route cost by product
    costSegment:{},// Route cost by product segment
    volume:{},     // Route volume by product
    vehicle:[],    // Vehicle route relation
    results: [],
    defaultPlant:{},
    sales:[],       // Ventas centro / producto
    materials:{},   // Materiales venta y abasto
    resultsVtas:[], // Costo Logistico y planta
    idOrigen:'',  //origin input filter
    idDestino:'', //destiny input filter
    producto:'',   //product input filter    
    saltos:'',     //jumps in a route
    window: { width: 0, height: 0 },
    showMap: false, //toggle Map show
    fullMap: false, //toggle Map in full mode
    selectedRoute: {}, //No route selected by default
    fileUploaded: '',
    displayFormat:'tabla', //Show either table or summary when shrink
    overlay: false,
    selectOrigen:'',
    itemsList:[],
    overlayDestino:false,
    selectDestino:'',
    itemsListDestino:[],
    overlayProducto:false,
    selectProducto:'',
    itemsListProducto:[],
    marcadoresPlanta:[],
    chartData: {
      labels: [ 
        '00:00', '01:00', '02:00', '03:00', '04:00', '05:00','06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
        '12:00', '13:00', '14:00', '15:00', '16:00', '17:00','18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
      ],
      datasets: 
      [
        {
          data: [7,2,20,10,7],
          borderColor: ['black','black','black','black','black'],
          backgroundColor: ['blue','blue','blue','blue','blue'],
          hoverBackgroundColor: ['green','green','green','green','green'],
          borderWidth: 1,
          order: 2
        },
        {
          type: 'line',
          label: 'Capacidades',
          data: [5,5,5,5,5,5,5,5,5,5,5,5],
          borderColor: 'red',
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 1,
          backgroundColor: 'rgba(155,205,151, 0)',
          hoverBackgroundColor: 'rgba(155,205,151, 0)',
          order: 1
        }
      ]
    },
    chartDataRadar:{
      labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'],
      datasets: [
        {
          label: 'My First dataset',
          backgroundColor: 'rgba(179,181,198,0.2)',
          borderColor: 'rgba(179,181,198,1)',
          pointBackgroundColor: 'rgba(179,181,198,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          data: [65, 59, 90, 81, 56, 55, 40]
        },
        {
          label: 'My Second dataset',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          pointBackgroundColor: 'rgba(255,99,132,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(255,99,132,1)',
          data: [28, 48, 40, 19, 96, 27, 100]
        }
      ]
    },
    chartDataPie:{
      labels: ['1 salto', '2 saltos', '3 saltos', '4 saltos','+Mas'],
      datasets: [
        {
          backgroundColor: [
            '#41B883',
            '#E46651',
            '#00D8FF',
            '#DD1B16',
            '#B7A242'
          ],
          data: [40, 20, 80, 10,60]
        }
      ]
    },
    chartDataPolarArea:{
      labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'],
      datasets: [
        {
          label: 'My First dataset',
          backgroundColor: 'rgba(0,0,180,0.3)',
          pointBackgroundColor: 'rgba(0,0,180,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(0,0,180,1)',
          data: [65, 59, 90, 81, 56, 55, 40]
        },
        {
          label: 'My Second dataset',
          backgroundColor: 'rgba(255,0,0,0.3)',
          pointBackgroundColor: 'rgba(255,0,0,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(255,0,0,1)',
          data: [28, 48, 40, 19, 96, 27, 100]
        }
      ]
    },
    chartOptionsPolarArea:{
      responsive: true,
      animation: { duration: 0 },
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,      
      maintainAspectRatio: false,
    },
    chartOptionsPie:{
      responsive: true,
      animation: { duration: 0 },
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,      
      maintainAspectRatio: false,
    },
    chartOptionsRadar:{
      responsive: true,
      animation: { duration: 0 },
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,      
      maintainAspectRatio: false,
    },
    chartOptions: {
      responsive: true,
      animation: { duration: 0 },
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
      tooltips: { enabled: false },
      maintainAspectRatio: false,
      legend: { display: false },
      scales: {
        xAxes: [{
          stacked: true,
          barPercentage: 1,
          categoryPercentage: 0.9
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            callback: function(value, index, ticks) {
              return (value / 10)
            }
          }
        }]
      },
      elements: {
        line: {
          tension: 0
        }
      }
    },
    rutasABASTO:[],
    rutasABASTOFiltradasParaMapa:[],
    abastoNOM:[],
    entregasNOM:[],
    poligonosNOM:[],
    plantProduct:{},
    chainsBySegment:{}, /*{ 141600_141629_Bco CPC 25k:[[141201,12321],[123432,789543]],...} -> opciones de encadenado por segemento */
    ytdRoutes:[]
  }),
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  mounted(){
    EventBus.$on('sendIndex', coors => {
      console.log(coors)
    });
    EventBus.$on('setOrder', coors => {
      console.log(coors)
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    clearFilters(limpia='todos'){
      this.idOrigen= []
      this.idDestino= []
      this.producto= []
      this.saltos= ""
    },
    haversine_distance(mk1={lat:0,lng:0}, mk2={lat:0,lng:0}) {
      
      var R = 6371.0710 // 3958.8 Radius of the Earth in miles
      var rlat1 = mk1.lat * (Math.PI/180); // Convert degrees to radians
      var rlat2 = mk2.lat * (Math.PI/180); // Convert degrees to radians
      
      var difflat = rlat2-rlat1; // Radian difference (latitudes)
      var difflon = (mk2.lng-mk1.lng) * (Math.PI/180); // Radian difference (longitudes)

      var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
      return d*1.25;
    },
    sendFilteredRoutesToMap(){      
      this.rutasABASTOFiltradasParaMapa=[]
      this.getRoutes.forEach(item =>{
        this.setSelectedRoute(item, 0)
      })
      this.rutasABASTO = [...this.rutasABASTOFiltradasParaMapa]
      this.limpiaSeleccionMapa = true;
    },
    cancelSelectedRoute(){
      this.selectedRoute = {}
      this.rutasABASTO=[]
      this.limpiaSeleccionMapa = false;
    },
    numberWithCommas(n) {
      var parts = n.toString().split(".");
      return (
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        (parts[1] ? "." + parts[1].substr(0, 2) : "")
      );
    },
    numberWithCommasND(n) {
      var parts = n.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setSelectedKPI(kpi){
      this.selectedKpi = kpi

      if(kpi == 'pie'){
        this.chartDataPie.datasets[0].data=[0,0,0,0,0]        
        this.routes.forEach((route,index) =>{          
          this.chartDataPie.datasets[0].data[route.saltos-1]+=1
        })

      }
    },
    setSelectedRoute(item, unique){
      let rutaItems= JSON.parse(JSON.stringify(item))
      rutaItems["puntos"]=[] //Agrega un nuevo campo
      
      item.cadena.forEach( (ubicacion,indice) =>{        
        if(this.cb[ubicacion] !== undefined){          
          rutaItems.puntos.push({
            id: ubicacion,
              extra:{
                idsap: this.cb[ubicacion].idsap                
              },
              nombre: this.cb[ubicacion].descripcion,
              ubicacion:{
                lat: this.cb[ubicacion].lat*1,
                lng: this.cb[ubicacion].lng*1
              }
          })
        }        
      })

      if(unique ==1 ){ //Esta seleccionando rutas 1 por 1
        this.rutasABASTO=[rutaItems]
        this.selectedRoute = item
        this.limpiaSeleccionMapa = true;
      }
      else{
        this.rutasABASTOFiltradasParaMapa.push(rutaItems)
      }
    },
    loadFile() {
      if (this.file.id) {
        let fr = new FileReader();
        let file = this.file.id
        let size = this.file.id.size        
        this.fileUploaded = file.name+' '+ (size/1000)+'kb'
        if (size <= 5000000) {
          this.loading = true
          setTimeout(() => {
            fr.onload = () => {
              let data = XLSX.read(fr.result, { type: "array" })
              let sheetNames = data.SheetNames
              let fileNOM = false
              let fileVentas = false
              let fileAbasto = false
              
              //Identifica que tipo de archivo es ABASTO, VENTAS o NOM
              if(data.SheetNames.find(element => element ==='relation')){
                fileAbasto = true
              }
              else if(data.SheetNames.find(element => element ==='ventas')){
                fileVentas = true
              }
              else{
                fileNOM = true
              }

              let numSheets = Object.keys(data.Sheets).length
              let worksheet
              let csv
              let values

              if(numSheets >= 1){
                worksheet = data.Sheets[sheetNames[0]]
                csv = XLSX.utils.sheet_to_csv(worksheet).trim()
                values = Papa.parse(csv)
                if (values.data && values.data.length) {
                  values = values.data
                  if(fileNOM || fileAbasto){ 
                    this.parsePlantCatalog(values)
                  }
                  else if(fileVentas){
                    this.parseMaterialVta(values)                    
                  }
                }
                else {
                  this.loading = false
                  this.error = true
                  this.msgError = 'El archivo no fue leído correctamente'
                  setTimeout(() => { this.error = false }, 2000);
                  return false
                }
              }
              if(numSheets >= 2){
                worksheet = data.Sheets[sheetNames[1]]
                csv = XLSX.utils.sheet_to_csv(worksheet).trim()
                values = Papa.parse(csv)
                if (values.data && values.data.length) {
                  values = values.data                
                  if(fileNOM){ 
                    this.parseAbastoNOM(values) 
                  }
                  else if(fileAbasto){ 
                    this.parseCB(values) 
                  }
                  else if(fileVentas){
                    this.parseSales(values)
                  }
                }
                else {
                  this.loading = false
                  this.error = true
                  this.msgError = 'El archivo no fue leído correctamente'
                  setTimeout(() => { this.error = false }, 2000);
                  return false
                }
              }
              if(numSheets >= 3){
                worksheet = data.Sheets[sheetNames[2]]
                csv = XLSX.utils.sheet_to_csv(worksheet).trim()
                values = Papa.parse(csv)
                if (values.data && values.data.length) {
                  values = values.data
                  if(fileNOM){ 
                    this.parseEntregasNOM(values) 
                  }
                  else if(fileAbasto){ 
                    this.parseCost(values)
                  }
                  
                }
                else {
                  this.loading = false
                  this.error = true
                  this.msgError = 'El archivo no fue leído correctamente'
                  setTimeout(() => { this.error = false }, 2000);
                  return false
                }
              }
              if(numSheets >= 4){
                worksheet = data.Sheets[sheetNames[3]]
                csv = XLSX.utils.sheet_to_csv(worksheet).trim()
                values = Papa.parse(csv)
                if (values.data && values.data.length) {
                  values = values.data
                  if(fileNOM){ 
                    this.parsePoligonosNOM(values)
                  }
                  else if(fileAbasto){ 
                    this.parseVehicle(values)
                  }                
                }
                else {
                  this.loading = false
                  this.error = true
                  this.msgError = 'El archivo no fue leído correctamente'
                  setTimeout(() => { this.error = false }, 2000);
                  return false
                }
              }
              if(numSheets >= 5){
                worksheet = data.Sheets[sheetNames[4]]
                csv = XLSX.utils.sheet_to_csv(worksheet).trim()
                values = Papa.parse(csv)
                if (values.data && values.data.length) {
                  values = values.data
                  if(fileAbasto){
                    this.parsePlantProduct(values)
                  }
                }
                else {
                  this.loading = false
                  this.error = true
                  this.msgError = 'El archivo no fue leído correctamente'
                  setTimeout(() => { this.error = false }, 2000);
                  return false
                }
              }
              if(numSheets >= 6){
                worksheet = data.Sheets[sheetNames[5]]
                csv = XLSX.utils.sheet_to_csv(worksheet).trim()
                values = Papa.parse(csv)
                if (values.data && values.data.length) {
                  values = values.data
                  if(fileAbasto){
                    this.parseYTDRoutes(values)
                  }
                }
                else {
                  this.loading = false
                  this.error = true
                  this.msgError = 'El archivo no fue leído correctamente'
                  setTimeout(() => { this.error = false }, 2000);
                  return false
                }
              }
              if(numSheets >= 7){
                worksheet = data.Sheets[sheetNames[6]]
                csv = XLSX.utils.sheet_to_csv(worksheet).trim()
                values = Papa.parse(csv)
                if (values.data && values.data.length) {
                  values = values.data
                  this.routes=[]
                  if(fileAbasto){
                    this.parseRoutes(values)
                  }
                }
                else {
                  this.loading = false
                  this.error = true
                  this.msgError = 'El archivo no fue leído correctamente'
                  setTimeout(() => { this.error = false }, 2000);
                  return false
                }
              }
              this.loading = false
            }
            fr.onerror = () => {
              this.loading = false
              this.file.id = null
              this.error = true
              this.msgError = 'El archivo no fue leído correctamente'
              setTimeout(() => { this.error = false }, 2000);
            }
            fr.readAsArrayBuffer(file);
          }, 1000);
        }
        else {
          this.error = true
          this.msgError = 'El archivo ha excedido el límite permitido (5 MB)'
          setTimeout(() => { this.error = false }, 2000);
        }
      }
      else {
        this.error = true
        this.msgError = 'Selecciona un archivo'
        setTimeout(() => { this.error = false }, 2000);
        this.routes = []
        this.fileUploaded = ''
      }
    },
    parseSales(items){
      let costoODP = 0
      let findCB, findODP, destino, ruta, costoRuta, plantaDefault, plantaDefaultCosto, findODP2, ruta2, costoRuta2, esPlanta, plantaCB, costoCB, planta, costo

      for (let i = 1; i < items.length; i++) {
        let centro = items[i][0]
        let material = items[i][1].trim()
        planta = ''
        costo = ''

        if (!_.isNil(centro) && centro.trim() !== '' && !_.isNil(material) && material.trim() !== '') {
          
          findCB = this.findByMatchingProperties(Object.values(this.cb), {
            idsap: centro
          })
          destino = findCB.length>0 ? findCB[0].id : ''
          
          //Búsqueda 1: El destino es planta.
          esPlanta = destino != '' && this.cb[destino] ? 
                    (this.cb[destino].descripcion.toUpperCase().indexOf("PLANTA")> -1 || this.cb[destino].descripcion.toUpperCase().indexOf("PTA.")> -1)? 1 : 0 
                    : 0
          
          //Búsqueda 2 : Costo y Planta por destino y producto completo
          findODP = destino != '' ? this.findByMatchingProperties(this.routes, {
            idDestino: destino,
            producto: this.materials[material].material_abasto
          }): []
          ruta = findODP.length>0 ? findODP[0] : {}
                    
          costoRuta = Object.keys(ruta).length > 0 && this.cost[ruta.idOrigen+'_'+ruta.idDestino+'_'+ruta.producto] ? this.cost[ruta.idOrigen+'_'+ruta.idDestino+'_'+ruta.producto] : 0

          //Busqueda 3 : Costo y Planta por destino y producto completo en el YTDRoutes
          let busca = true
          let origins = [destino]
          let planta3 = ''
          let costoRuta3 = 0
          while(busca){
            const findODPYTD = this.findByMatchingProperties(this.ytdRoutes, {
              idDestino: origins[0],
              producto: this.materials[material].material_abasto
            })
            //El arreglo de objetos lo convierte en arreglo de ids de los origenes
            const busquedaYTD = [...new Set(findODPYTD.map(a => a.idOrigen))];
            origins = origins.concat(busquedaYTD)

            //Revisa que algún origen encontrado sea planta y produzca mismo producto
            for(let x= 0; x < busquedaYTD.length; x++){
              if(_.has(this.plants, busquedaYTD[x])){
                if(this.plantProduct[busquedaYTD[x]+"_"+this.materials[material].material_abasto] !== undefined){ //Revisa que produzca esa planta ese producto
                  planta3 = ''+busquedaYTD[x];
                  
                  const finds = this.findByMatchingProperties(this.ytdRoutes, {
                    idDestino: destino,
                    producto: this.materials[material].material_abasto
                  })                  
                  finds.forEach(a => {                    
                    costoRuta3 += (a.costoRuta*1)
                  })
                  costoRuta3 = costoRuta3 / finds.length

                  break;
                }
              }
            }
            if(planta3 !== '' || origins.length < 1){
              busca = false
            }
            else{
              origins.shift();
            }
          }

          //Búsqueda 4: Costo y Planta por planta cualquier presentacion y material cualquier presentación
          /* //plantaDefault = this.defaultPlant[destino+"-"+this.materials[material].material_abasto_granel] ?? ''
          
          findODP2 = plantaDefault != '' ? this.findByMatchingProperties(this.routes, {
            planta: plantaDefault,
            producto: this.materials[material].material_abasto_granel
          }) : []
          ruta2 = findODP2.length>0 ? findODP2[0] : {}
                 
          costoRuta2 = Object.keys(ruta2).length > 0 && this.cost[ruta2.idOrigen+'_'+ruta2.idDestino+'_'+ruta2.producto] ? this.cost[ruta2.idOrigen+'_'+ruta2.idDestino+'_'+ruta2.producto] : 0 */

          busca = true
          origins = [destino]
          let planta4 = ''
          let costoRuta4 = 0
          while(busca){
            const findsyd = this.findByMatchingProperties(this.ytdRoutes, {
              idDestino: origins[0],
              producto: this.materials[material].material_abasto_granel
            })
            //El arreglo de objetos lo convierte en arreglo de ids de los origenes
            const busqueda = [...new Set(findsyd.map(a => a.idOrigen))];
            origins = origins.concat(busqueda)

            //Revisa que algún origen encontrado sea planta y produzca mismo producto
            for(let x= 0; x < busqueda.length; x++){
              if(_.has(this.plants, busqueda[x])){
                if(this.plantProduct[busqueda[x]+"_"+this.materials[material].material_abasto_granel] !== undefined){ //Revisa que produzca esa planta ese producto
                  planta4 = ''+busqueda[x];
                  
                  const finds = this.findByMatchingProperties(this.ytdRoutes, {
                    idDestino: destino,
                    producto: this.materials[material].material_abasto_granel
                  })                  
                  finds.forEach(a => {                    
                    costoRuta4 += (a.costoRuta*1)
                  })
                  costoRuta4 = costoRuta4 / finds.length

                  break;
                }
              }
            }
            if(planta4 !== '' || origins.length < 1){
              busca = false
            }
            else{
              origins.shift();
            }
          }

          //plantaCB = ruta.planta ? ruta.planta : esPlanta > 0 ? centro : plantaDefault ? plantaDefault : ''

          plantaCB = (esPlanta > 0 && this.plantProduct[destino+"_"+this.materials[material].material_abasto] !== undefined)? centro : ruta.planta ? ruta.planta : planta3 ? planta3 : planta4 ? planta4 : ''
          
          planta = plantaCB != '' && !isNaN(plantaCB) ? this.cb[plantaCB].idsap : plantaCB
          
          //costo = costoRuta ? costoRuta : esPlanta > 0 ? 1 : costoRuta2 ? costoRuta2 : 0
          costo = (esPlanta > 0 && this.plantProduct[destino+"_"+this.materials[material].material_abasto] !== undefined) ? 1 : costoRuta ? costoRuta : planta3 !== ''? costoRuta3: costoRuta4 > 0 ? costoRuta4 : 0


          this.sales.push({ centro, material, cb:destino, esPlanta,
            plantaB1: centro, costoB1: 1,
            plantaB2: (ruta.planta??''), costoB2: costoRuta,
            plantaB3: planta3, costoB3: costoRuta3,
            plantaB4: planta4, costoB4: costoRuta4,
            /* plantaB1: (ruta.planta??''), costoB1: costoRuta,
            plantaB2: plantaDefault, costoB2: costoRuta2,
            plantaB3: centro, costoB3: 1, */
            plantaCB,
            planta, costo })
        }
      }
      /* console.log("Sales", this.sales)
      console.log("Routes", this.routes)
      console.log("Planta Default", this.defaultPlant) */
    },
    parseMaterialVta(items){
      let material, material_abasto,material_abasto_granel,key
      for (let i = 1; i < items.length; i++) {
        material = items[i][0]
        material_abasto = items[i][1]
        material_abasto_granel = items[i][2]
        if (!_.isNil(material) && material.trim() !== '' && !_.isNil(material_abasto) && material_abasto.trim() !== '' && !_.isNil(material_abasto_granel) && material_abasto_granel.trim() !== '') {
          key = material.trim()
          this.materials[key]= { material_abasto, material_abasto_granel }
        }
      }
    },
    parsePlantCatalog(items) {
      for (let i = 1; i < items.length; i++) {
        let planta = items[i][0]
        if (!_.isNil(planta) && planta.trim() !== '') {
          let key = planta.trim()
          this.plants[key]= { id: items[i][0], nombre: items[i][1], idsap: items[i][2], costo: items[i][3], lat:items[i][4],lon:items[i][5]}
        }
      }

      this.marcadoresPlanta = []
      let plantasId = Object.keys(this.plants)      
      for(let i=0; i< plantasId.length; i++){
        if(this.plants && this.plants[plantasId[i]]){          
          this.marcadoresPlanta.push({
            id: this.plants[plantasId[i]].id,
            extra:{
              idsap: this.cb[this.plants[plantasId[i]].id]?this.cb[this.plants[plantasId[i]].id].idsap:''
            },
            nombre: this.plants[plantasId[i]].nombre,
            ubicacion:{
              lat: this.plants[plantasId[i]].lat*1,
              lng: this.plants[plantasId[i]].lon*1
            },
          })
        }
      }
    },
    parseCB(items) {
            
      for (let i = 1; i < items.length; i++) {
        let cb = items[i][0]
        if (!_.isNil(cb) && cb.trim() !== '') {
          let key = cb.trim()
          this.cb[key]={ 
            id:items[i][0], idsap:items[i][1], descripcion:items[i][2], lat:items[i][3], lng:items[i][4] 
          }
        }
      }
            
    },
    parseCost(items) {
      for (let i = 1; i < items.length; i++) {
        let costo = items[i][0]
        if (!_.isNil(costo) && costo.trim() !== '') {
          let key = costo.trim()
          this.cost[key]=items[i][1]
          this.costSegment[key]=items[i][2]
        }
      }      
    },
    parseVehicle(items) {
      for (let i = 1; i < items.length; i++) {
        let origen = items[i][0]
        let destino = items[i][1]
        let producto = items[i][2]
        let transporte = items[i][3]
        let vehiculo = items[i][4]
        let tonAbasto = items[i][5]
        let fleteAbasto = items[i][6]
        let tonParaVta = items[i][7]
        let fleteParaVta = items[i][8]

        if (!_.isNil(origen) && origen.trim() !== '' && !_.isNil(destino) && destino.trim() !== '' && !_.isNil(producto) && producto.trim() !== '' && !_.isNil(transporte) && transporte.trim() !== '' && !_.isNil(vehiculo) && vehiculo.trim() !== '') {
          this.vehicle.push({idOrigen: origen, idDestino: destino, producto, transporte, vehiculo, tonAbasto, fleteAbasto, tonParaVta, fleteParaVta})
        }
      }
    },
    parsePlantProduct(items){
      let relPP = {}
      for (let i = 1; i < items.length; i++) {
        //console.log(items[i][0]+"_"+items[i][1])
        relPP[items[i][0]+"_"+items[i][1]]=i
      }
      this.plantProduct = JSON.parse(JSON.stringify(relPP))
    },
    parseYTDRoutes(items){      
      for (let i = 1; i < items.length; i++) {        
        this.ytdRoutes.push({ 
          idOrigen: items[i][0], 
          idDestino: items[i][1], 
          producto: items[i][2],
          transporte: items[i][3],
          vehiculo: items[i][4],
          volTramo: items[i][5]*1,
          costoTramo: items[i][6]*1,
          volRuta: items[i][7]*1,
          costoRuta: items[i][8]*1,
        })
      }      
    },
    parseRoutes(items) {
      let listadoUnicos = {}
      let listadoUnicoProductos ={}
      let tempItemsList = []
      let tempItemsListProducto = []

      for (let i = 1; i < items.length; i++) {
        let origen = items[i][0]
        let destino = items[i][1]
        let producto = items[i][2]
        let volumen = items[i][3]
        let volumenTramo = items[i][4]

        let distancia = 0
        let origin = this.cb[origen]?{lat: this.cb[origen].lat*1, lng:this.cb[origen].lng*1}:{lat:0,lng:0}
        let destiny = this.cb[destino]?{lat: this.cb[destino].lat*1, lng:this.cb[destino].lng*1}:{lat:0,lng:0}
        if(!(origin.lat==0 || origin.lng==0 || destiny.lat==0 || destiny.lng==0)){
          distancia = this.haversine_distance(origin,destiny)*1
        }
        let costoRuta = this.cost[origen+"_"+destino+"_"+producto] ?? 0
        let costoTramo = this.costSegment[origen+"_"+destino+"_"+producto] ?? 0

        const findVehicle = this.findByMatchingProperties(this.vehicle, {
                    idOrigen:  origen,
                    idDestino: destino,
                    producto: producto
                  })
                  
        const vehiculos = [...new Set(findVehicle.map(a => a.vehiculo))].join('|').trim()
        const transportes = [...new Set(findVehicle.map(a => a.transporte))].join('|').trim()
        
        const costoTramoKm = distancia < 1 ? -1 : this.numberWithCommas(costoTramo / distancia)

        const origenNombre = (this.cb[origen]? this.cb[origen].descripcion : origen)
        const destinoNombre = (this.cb[destino]? this.cb[destino].descripcion : destino)

        if (!_.isNil(origen) && origen.trim() !== '' && !_.isNil(destino) && destino.trim() !== '' && !_.isNil(producto) && producto.trim() !== '') {
          this.routes.push({renglon:(i+1),idOrigen: origen, idDestino: destino, producto, cadena:[], cadenaNombres:[], cadenaCosto:[], cadenaCostoTramo:[], cadenaAcumCosto:[], planta:'', costoProduccion:0, cadenaProducto:[], prodDif:0, saltos:0, cadenaVehiculo:[], volumen, volumenTramo, cadenaVolumenTramo:[],cadenaVolumen:[], cadenaTransporte:[], cadenaDistancias:[], distancia, costoTramo, costoRuta, transporteTramo: transportes, vehiculoTramo: vehiculos, costoTramoKm, origenNombre, destinoNombre})
        }
        listadoUnicos[origen]=1
        listadoUnicos[destino]=1
        listadoUnicoProductos[producto]=1
      }

      let tempListKeys = Object.keys(listadoUnicos)
      tempListKeys.forEach(entry =>{
        if(this.cb[entry]){          
          tempItemsList.push({ text:entry+' '+this.cb[entry].idsap+' '+this.cb[entry].descripcion, value: entry, descripcion: this.cb[entry].descripcion})
        }
      })
      tempItemsList.sort(function(a,b){
        if (!isNaN(parseFloat(a.descripcion)) && !isNaN(parseFloat(b.descripcion))){
          return b.descripcion - a.descripcion;
        }
        else{
          if ( a.descripcion < b.descripcion ) return -1;
          if ( a.descripcion > b.descripcion ) return 1;
          return 0;
        }
      });      
      this.itemsList = [...tempItemsList]
      this.itemsListDestino = [...tempItemsList]

      let tempListKeysProductos = Object.keys(listadoUnicoProductos)
      tempListKeysProductos.forEach(entry =>{
        tempItemsListProducto.push({ text:entry, value: entry, descripcion: entry })
      })
      tempItemsListProducto.sort(function(a,b){
        if (!isNaN(parseFloat(a.descripcion)) && !isNaN(parseFloat(b.descripcion))){
          return b.descripcion - a.descripcion;
        }
        else{
          if ( a.descripcion < b.descripcion ) return -1;
          if ( a.descripcion > b.descripcion ) return 1;
          return 0;
        }
      });      
      this.itemsListProducto = [...tempItemsListProducto]      

      this.buildFullRoute(this.routes,[],[],[])
      this.findDefaultPlant()
      //console.log(this.defaultPlant)
      
    },
    parseAbastoNOM(items) {
      this.abastoNOM=[]
      for (let i = 1; i < items.length; i++) {
        let origen = items[i][0]
        let destino = items[i][1]
        let volumen = items[i][2]
        let lat_origen = items[i][3]
        let lng_origen = items[i][4]
        let lat_destino = items[i][5]
        let lng_destino = items[i][6]
        let nombre_origen = items[i][7]
        let nombre_destino = items[i][8]
        
        if (!_.isNil(origen) && origen.trim() !== '' && !_.isNil(destino) && destino.trim() !== '' && !_.isNil(volumen) && volumen.trim() !== '' ) {
          this.abastoNOM.push({idOrigen: origen, idDestino: destino, volumen,lat_origen,lng_origen,lat_destino,lng_destino,nombre_origen,nombre_destino})
        }
      }
    },
    parseEntregasNOM(items) {
      this.entregasNOM=[]
      for (let i = 1; i < items.length; i++) {
        let origen = items[i][0]
        let destino = items[i][1]
        let volumen = items[i][2]
        let lat_origen = items[i][3]
        let lng_origen = items[i][4]
        let lat_destino = items[i][5]
        let lng_destino = items[i][6]
        let nombre_origen = items[i][7]
        let nombre_destino = items[i][8]
        
        if (!_.isNil(origen) && origen.trim() !== '' && !_.isNil(destino) && destino.trim() !== '' && !_.isNil(volumen) && volumen.trim() !== '' ) {
          this.entregasNOM.push({idOrigen: origen, idDestino: destino, volumen,lat_origen,lng_origen,lat_destino,lng_destino,nombre_origen,nombre_destino})
        }
      }
    },
    parsePoligonosNOM(items) {
      this.poligonosNOM=[]
      for (let i = 1; i < items.length; i++) {
        let idPoligono = items[i][0]
        let nombre = items[i][1]
        let color = items[i][2]
        let path = items[i][3]
        let tipo = items[i][4]
        
        if (!_.isNil(idPoligono) && idPoligono.trim() !== '' && !_.isNil(nombre) && nombre.trim() !== '' && !_.isNil(path) && path.trim() !== '' ) {
          this.poligonosNOM.push({idPoligono, nombre, color, path, tipo})
        }
      }
      //console.log("poligonos", this.poligonosNOM)
    },
    findDefaultPlant(){
      // Busca para cada destino-producto una planta default, 
      // en la búsqueda si hay más de 1 opción selecciona la que tiene mayor volumen
      // y para el producto lo busca sin presentación
      for(let index=0; index< this.routes.length; index++){

        let tempProducto = (this.routes[index].producto.lastIndexOf(" ") > 0 ? this.routes[index].producto.substring(0, this.routes[index].producto.lastIndexOf(" ")) : this.routes[index].producto)
        
      //if(this.routes[index].idDestino === '141201' && this.routes[index].producto === 'CPC 50k'){
        let busca=1
        let tempDestino = this.routes[index].idDestino
        while(busca > 0){

          const opciones = this.findByDestinyProduct(this.routes,{
            idDestino: tempDestino,
            producto: tempProducto
          })
          //console.log('opciones',opciones)
          
          if(opciones.length > 0){
            const max = opciones.reduce(function(prev, current) {
                return (prev && (prev.volumen*1) > (current.volumen*1)) ? prev : current
            }) //returns object

            
            if(_.has(this.plants, max.idOrigen)){
              this.defaultPlant[max.idDestino+'-'+tempProducto]= max.idOrigen
              busca=0
            }
            else{
              tempDestino = max.idOrigen
            }
          }
          else{
            busca=0
          }
        }
      //}

      }
    },
    buildFullRoute(pRoutes, originChain, productChain, level) {

      for(let index=0; index< pRoutes.length; index++){
        let planta = ''
        let deTabla= 0

        //Encuentra combinaciones Origen-Destino-Producto del origen como destino
        const findODP = this.findByMatchingProperties(this.routes, {
          idDestino: pRoutes[index].idOrigen,
          producto: pRoutes[index].producto
        })        
        
        //El arreglo de objetos lo convierte en arreglo de ids de los origenes
        const busqueda = [...new Set(findODP.map(a => a.idOrigen))];

        //Revisa que algún origen encontrado sea planta y produzca mismo producto
        for(let indiceB= 0; indiceB < busqueda.length; indiceB++){
          if(_.has(this.plants, busqueda[indiceB])){
            if(this.plantProduct[busqueda[indiceB]+"_"+pRoutes[index].producto] !== undefined){ //Revisa que produzca esa planta ese producto
              planta = ''+busqueda[indiceB];
              break;
            }
          }
        }

        //Si Origen-Producto están en la tabla, salió de ese origen, asignando como planta productora
        if(this.plantProduct[pRoutes[index].idOrigen+"_"+pRoutes[index].producto] !== undefined){          
          planta = pRoutes[index].idOrigen
          deTabla = 1
        }

        /* //Encuentra combinaciones Origen-Destino-Producto del origen como destino en YTDRoutes
        if(planta === ''){ //Si aún no encuentra planta
          let busca = true
          let origins = [pRoutes[index].idOrigen]
          while(busca){
            const findODPYTD = this.findByMatchingProperties(this.ytdRoutes, {
              idDestino: origins[0],
              producto: pRoutes[index].producto
            })
            //El arreglo de objetos lo convierte en arreglo de ids de los origenes
            const busquedaYTD = [...new Set(findODPYTD.map(a => a.idOrigen))];
            origins = origins.concat(busquedaYTD)

            //Revisa que algún origen encontrado sea planta y produzca mismo producto
            for(let x= 0; x < busquedaYTD.length; x++){
              if(_.has(this.plants, busquedaYTD[x])){
                if(this.plantProduct[busquedaYTD[x]+"_"+pRoutes[index].producto] !== undefined){ //Revisa que produzca esa planta ese producto
                  planta = ''+busquedaYTD[x];
                  break;
                }
              }
            }
            if(planta !== '' || origins.length < 1){
              busca = false
            }
            else{
              origins.shift();
            }
          }
        } */
        
        if(planta !== ''){ //Si encontró planta
          //Si está en el nivel de búsqueda mayor al inicial
          /* if(level.length > 0){
            //Agrega el origen de pRoutes a la cadena de busqueda
            originChain.push(''+pRoutes[index].idOrigen);
            productChain.push(''+pRoutes[index].producto);
          } */
          
          //Si lo encontró del listado y no está en un nivel inicial
          // guardas el destino intermedio entre la planta y el origen que se busco como destino
          if(deTabla < 1 && level.length > 0){
            originChain.push(''+pRoutes[index].idOrigen);
          }
          //Guarda el producto en la cadena de productos
          productChain.push(''+pRoutes[index].producto);
          //Agrega la planta encontrada
          originChain.push(planta);
          
          //Agrega la planta encontrada
          //if(planta != -1000){ //Este if se agregó para cuando la planta esta en tabla plantaproducto
          //originChain.push(planta);
          //}
        }
        else{ //Si no encontró planta
          
          //Si está en un nivel de búsqueda mayor al inicial
          //guardas el destino y el producto que se busco intermedio
          if(level.length > 0){
            //Agrega el origen de pRoutes a la cadena de busqueda
            originChain.push(''+pRoutes[index].idOrigen);
            productChain.push(''+pRoutes[index].producto);
          }
                    
          //Actualiza el nivel de búsqueda +1
          level.push(1);
                        
          if(findODP.length > 0){ //Si hay listado de origenes
            //sigue buscando planta, pero ahora de ese listado
            this.buildFullRoute(findODP, originChain, productChain, level);
          }          
          /* else{ //Si no hay opciones, busca con producto más general - sin presentación
            const findODP2 = this.findByMatchingProperties(this.routes, {
              idDestino: pRoutes[index].idOrigen,
              producto: pRoutes[index].producto.substring(0, pRoutes[index].producto.lastIndexOf(" "))
            })
            this.buildFullRoute(findODP2, originChain, productChain, level);
          } */
          //Cuando termina, actualiza nivel -1
          level.pop();
        }

        //Esta en el nivel inicial
        if(level.length < 1){
          
          //Encuentra y recorta cadena al encontrar la primera planta
          let tempCombinado=[]
          //console.log('originChain:'+index+'.-',originChain, productChain)
          for(let w=0; w < originChain.length; w++){
            tempCombinado.push(originChain[w])

            if(_.has(this.plants, originChain[w])){
              //console.log(originChain[w]+"_"+pRoutes[index].producto)
              if(this.plantProduct[originChain[w]+"_"+pRoutes[index].producto] !== undefined){
                pRoutes[index].planta = originChain[w]
                pRoutes[index].costoProduccion = this.plants[originChain[w]].costo
                break;
              }
              //else{
              //  tempCombinado.pop()                
              //}
            }
          }

          //Actualiza originChain y productChain con el recorte
          originChain = [...tempCombinado]
          productChain = productChain.slice(0,tempCombinado.length)

          //Mete la ruta original con la que inició la búsqueda
          if(deTabla < 1){
            originChain.unshift(''+pRoutes[index].idDestino,''+pRoutes[index].idOrigen);
            productChain.unshift(pRoutes[index].producto)
          }
          else{
            originChain.unshift(''+pRoutes[index].idDestino);
          }

          //Actualiza en pRoutes la cadena
          //console.log('finalChains:'+index+'.-',originChain, productChain)
          pRoutes[index].cadena = JSON.parse(JSON.stringify(originChain));
          pRoutes[index].cadenaProducto = JSON.parse(JSON.stringify(productChain));

          /*** Inicia rescate ***/
          // Ya no encontró planta buscando encadenado
          // Pero si el origen inicial es planta, la toma como su origen
          /* if(pRoutes[index].planta === ''){
            if(_.has(this.plants, this.routes[index].idOrigen)){
              if(this.plantProduct[this.routes[index].idOrigen+"_"+this.routes[index].producto] !== undefined){
                this.routes[index].planta = ''+this.routes[index].idOrigen;
                this.routes[index].costoProduccion = this.plants[this.routes[index].idOrigen].costo
              }
            }
          } */
          //Encuentra combinaciones Origen-Destino-Producto del origen como destino en YTDRoutes
          if(pRoutes[index].planta === ''){ //Si aún no encuentra planta
            let busca = true
            let origins = [this.routes[index].idOrigen]
            while(busca){
              const findODPYTD = this.findByMatchingProperties(this.ytdRoutes, {
                idDestino: origins[0],
                producto: this.routes[index].producto
              })
              //El arreglo de objetos lo convierte en arreglo de ids de los origenes
              const busquedaYTD = [...new Set(findODPYTD.map(a => a.idOrigen))];
              origins = origins.concat(busquedaYTD)

              //Revisa que algún origen encontrado sea planta y produzca mismo producto
              for(let x= 0; x < busquedaYTD.length; x++){
                if(_.has(this.plants, busquedaYTD[x])){
                  if(this.plantProduct[busquedaYTD[x]+"_"+this.routes[index].producto] !== undefined){ //Revisa que produzca esa planta ese producto
                    planta = ''+busquedaYTD[x];
                    this.routes[index].planta = planta;
                    this.routes[index].costoProduccion = this.plants[busquedaYTD[x]].costo
                    break;
                  }
                }
              }
              if(planta !== '' || origins.length < 1){
                busca = false
              }
              else{
                origins.shift();
              }
            }
          }

          if(pRoutes[index].planta === ''){ //Si aún no encuentra planta BUSCA CON GRANEL
            let busca = true
            let origins = [this.routes[index].idOrigen]
            let product = this.routes[index].producto.substring(0, this.routes[index].producto.lastIndexOf(" "))
            while(busca){
              const findODPYTD = this.findByMatchingProperties(this.routes, {
                idDestino: origins[0],
                producto: product
              })
              //El arreglo de objetos lo convierte en arreglo de ids de los origenes
              const busquedaYTD = [...new Set(findODPYTD.map(a => a.idOrigen))];
              origins = origins.concat(busquedaYTD)

              //Revisa que algún origen encontrado sea planta y produzca mismo producto
              for(let x= 0; x < busquedaYTD.length; x++){
                if(_.has(this.plants, busquedaYTD[x])){
                  if(this.plantProduct[busquedaYTD[x]+"_"+product] !== undefined){ //Revisa que produzca esa planta ese producto
                    planta = ''+busquedaYTD[x];
                    this.routes[index].planta = planta;
                    this.routes[index].costoProduccion = this.plants[busquedaYTD[x]].costo
                    break;
                  }
                }
              }
              if(planta !== '' || origins.length < 1){
                busca = false
              }
              else{
                origins.shift();
              }
            }
          }

          


          /*** Termina rescate ***/

          //Llena cadenas de nombres y costos del
          for (let i = originChain.length - 1; i >= 0; i--) {
            if(this.cb[Number(originChain[i])]){
              
              //nombres
              pRoutes[index].cadenaNombres.push(
                this.cb[Number(originChain[i])].idsap+ " "+
                this.cb[Number(originChain[i])].descripcion
              )
              
              if(i < originChain.length - 1){
                
                //costos
                let llave = originChain[i+1] + "_" + originChain[i] + "_" + pRoutes[index].producto
                let llave2 = originChain[i+1] + "_" + originChain[i] + "_" + pRoutes[index].producto.substring(0, pRoutes[index].producto.lastIndexOf(" "))
                
                let producto=''

                let origin = {lat: this.cb[originChain[i+1]].lat*1, lng:this.cb[originChain[i+1]].lng*1}
                let destiny = {lat: this.cb[originChain[i]].lat*1, lng:this.cb[originChain[i]].lng*1}
                if(!(origin.lat==0 || origin.lng==0 || destiny.lat==0 || destiny.lng==0)){
                  pRoutes[index].cadenaDistancias.push(this.haversine_distance(origin,destiny)*1)
                }
                else{
                  pRoutes[index].cadenaDistancias.push(0)
                }

                if(_.has(this.cost, llave)){
                  pRoutes[index].cadenaCosto.push(this.cost[llave])
                  pRoutes[index].cadenaCostoTramo.push(this.costSegment[llave])                  
                  producto = pRoutes[index].producto
                }
                else if(_.has(this.cost, llave2)){
                  pRoutes[index].cadenaCosto.push(this.cost[llave2])
                  pRoutes[index].cadenaCostoTramo.push(this.costSegment[llave2])                  
                  producto = pRoutes[index].producto.substring(0, pRoutes[index].producto.lastIndexOf(" "))
                }
                else{
                  pRoutes[index].cadenaCosto.push(0)
                  pRoutes[index].cadenaCostoTramo.push(0)
                  producto=''
                }

                
                if(producto !== ''){
                  //vehiculos
                  const findVehicle = this.findByMatchingProperties(this.vehicle, {
                    idOrigen:  originChain[i+1],
                    idDestino: originChain[i],
                    producto: producto
                  })
                  
                  const vehiculos = [...new Set(findVehicle.map(a => a.vehiculo))].join('|').trim()
                  
                  pRoutes[index].cadenaVehiculo.push(vehiculos)

                  const transportes = [...new Set(findVehicle.map(a => a.transporte))].join('|').trim()
                  
                  pRoutes[index].cadenaTransporte.push(transportes)

                  //volumen
                  const findVolumen = this.findByMatchingProperties(this.routes, {
                    idOrigen:  originChain[i+1],
                    idDestino: originChain[i],
                    producto: producto
                  })
                  
                  pRoutes[index].cadenaVolumen.push(findVolumen.length>0 ? findVolumen[0].volumen : 0)
                  pRoutes[index].cadenaVolumenTramo.push(findVolumen.length>0 ? findVolumen[0].volumenTramo : 0)

                }
                else{
                  pRoutes[index].cadenaVehiculo.push('SinDetalle')
                  pRoutes[index].cadenaTransporte.push('SinDetalle')
                  pRoutes[index].cadenaVolumen.push(0)
                  pRoutes[index].cadenaVolumenTramo.push(0)
                }
              }
            }
            else{
              pRoutes[index].cadenaNombres.push(originChain[i])
            }
          }

          //Acumula los costos dependiendo del indice
          pRoutes[index].cadenaCostoTramo.forEach((costo,indice) =>{
            if(indice<1){
              pRoutes[index].cadenaAcumCosto.push(Number(costo))
            }
            else{
              pRoutes[index].cadenaAcumCosto.push(Math.round((Number(costo)+ pRoutes[index].cadenaAcumCosto[indice-1])*100)/100)
            }
          })
          
          //Ordena la cadena de indices para que el primero sea el inicio en pta
          pRoutes[index].cadena.reverse()
          pRoutes[index].cadenaProducto.reverse()          

          //Actualiza el número de productos diferentes en ruta
          pRoutes[index].prodDif = [...new Set(pRoutes[index].cadenaProducto)].length

          //Actualiza el número de saltos
          pRoutes[index].saltos = [...new Set(pRoutes[index].cadenaNombres)].length-1
          
          originChain=[];
          productChain=[];
        }
      }
      /* let target_origen = key.split('_')[0]
      let target_producto = key.split('_')[2]
                    
      target_producto = target_producto.replace(/\d{1,2}\.?\d{1,2}k/, '').trim()
      let last_node = target_origen
      for (let key2 in this.routes) {
        let source_origen = key2.split('_')[0]
        let source_destino = key2.split('_')[1]
        let source_producto = key2.split('_')[2]
        source_producto = source_producto.replace(/\d{1,2}\.?\d{1,2}k/, '').trim()
        if (last_node == source_destino && target_producto == source_producto) {
          this.routes[key].unshift([source_origen, source_destino])
          last_node = source_origen
          if (this.plants.indexOf(last_node) >= 0) {
            break
          }
        }
      } */      
    },
    findByMatchingProperties(set, properties) {
      return set.filter(function (entry) {
          return Object.keys(properties).every(function (key) {
              return entry[key] === properties[key];
          });
      });
    },
    findByDestinyProduct(set, properties) {
      return set.filter(function (entry) {
          return entry["idDestino"] === properties["idDestino"] &&
                 ((entry["producto"].lastIndexOf(" ") > 0 ? entry["producto"].substring(0, entry["producto"].lastIndexOf(" ")) : entry["producto"]) === properties["producto"])
      });
    },
    makeResult() {
      let csv = 'Renglón,Origen,Destino,Producto,Cadena,Cadena Nombre,Cadena Costo,Cadena Costo Tramo, Cadena AcumCosto, Planta, Costo Producción, Cadena Producto,ProdDif,Saltos,Cadena Transporte,Cadena Vehiculo, Cadena Volumen,Cadena Volumen Tramo,Cadena Distancias, Distancia, CostoTramoKm, CostoTramo, CostoRuta,Volumen Tramo, Transporte Tramo, Vehiculo Tramo, Origen Nombre, Destino Nombre\n'
      /* for (let key in this.routes) {
        let arr = key.split('_')
        let planta = ''
        if (this.routes[key].length) {
          let id = this.routes[key][0][0]
          if (this.plants.indexOf(id) >= 0) {
            planta = id
          }
        }
        csv += arr[0] + ',' + arr[1] + ',' + arr[2] + ',' + planta + '\n' 
        */
        this.getRoutes.forEach((route,index) =>{
          csv += route.renglon + ',' + 
                 route.idOrigen + ',' +
                 route.idDestino + ',' +
                 route.producto + ',' +
                 route.cadena.join('_') + ',' +
                 route.cadenaNombres.join('_') + ',' +
                 route.cadenaCosto.join('-') + ',' +
                 route.cadenaCostoTramo.join('-') + ',' +
                 route.cadenaAcumCosto.join('_') + ',' +
                 route.planta + ',' +
                 route.costoProduccion + ',' +
                 route.cadenaProducto.join('_') + ',' +
                 route.prodDif + ',' +
                 route.saltos + ',' +
                 route.cadenaTransporte.join('_') + ',' +
                 route.cadenaVehiculo.join('_') + ',' +
                 route.cadenaVolumen.join('_') + ',' +
                 route.cadenaVolumenTramo.join('_') + ',' +
                 route.cadenaDistancias.join('_') + ',' +
                 route.distancia+ ',' +
                 route.costoTramoKm+ ',' +
                 route.costoTramo+ ',' +
                 route.costoRuta+ ',' +
                 route.volumenTramo+ ',' +
                 route.transporteTramo + ',' +
                 route.vehiculoTramo + ',' +
                 route.origenNombre + ',' +
                 route.destinoNombre + ',' +
                  '\n'
        })      
      downloadjs(csv, 'Resultados.csv', 'text/csv')

      csv='Llave,Planta\n'
      Object.keys(this.defaultPlant).forEach(key =>{
        csv+= key+ ',' +
              this.defaultPlant[key]+
              '\n'
      })
      downloadjs(csv, 'PlantaDefault.csv', 'text/csv')

      csv='Centro, Material, Centro Beneficio, plantaB1, costoB1, EsPlanta, plantaB2, costoB2, plantaB3, costoB3, plantaB4, costoB4, PlantaCB, Planta, Costo\n'
      this.sales.forEach(key =>{
        csv+= key.centro + ',' +
              key.material + ',' +
              key.cb + ',' +
              key.plantaB1 + ',' +
              key.costoB1 + ',' +
              key.esPlanta + ',' +
              key.plantaB2 + ',' +
              key.costoB2 + ',' +              
              key.plantaB3 + ',' +
              key.costoB3 + ',' +              
              key.plantaB4 + ',' +
              key.costoB4 + ',' +
              key.plantaCB + ',' +
              key.planta + ',' +
              key.costo + ',' +
              '\n'
      })
      downloadjs(csv, 'ResultadosVentas.csv', 'text/csv')

      this.cancel()
    },
    cancel() {
      this.file = {
        id: [],
        data: null        
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - 160;
    }
  },  
  computed:{
    getSummary(){
      let summary = { cantidad:0, volumen:0, volumenRuta:0, costoTnSeg:0, costoTn:0}
      let rutas = [... this.getRoutes]
      let fleteVisible = 0
      let fleteVisibleRuta = 0
      let produccion = 0
      let produccionRuta = 0

      summary.cantidad = rutas.length
      rutas.forEach((item,index) =>{        
        summary.volumen += (item.volumenTramo*1)
        summary.volumenRuta += (item.volumen*1)
        fleteVisible += (item.costoTramo * item.volumenTramo)        
        fleteVisibleRuta += (item.costoRuta * item.volumen)
        produccion +=(item.costoProduccion * item.volumenTramo) 
        produccionRuta += (item.costoProduccion * item.volumen)
      })
      summary.costoTnSeg = fleteVisible / summary.volumen
      summary.costoTn = fleteVisibleRuta / summary.volumenRuta
      summary.costoTnProd = produccion / summary.volumen
      summary.costoTnSegProd = produccionRuta / summary.volumenRuta
      return summary
    },
    getRoutes: function () {
      if(this.routes){
        let result = this.routes;
        if(this.idOrigen){
          let origenes = this.idOrigen.map(a =>a.value.toString().toLocaleUpperCase())
          if(origenes.length>0){
            //result = result.filter((ruta) => ruta.idOrigen === this.idOrigen.value )
            result = result.filter((ruta) => origenes.includes(ruta.idOrigen) )
          }
        }
        if(this.idDestino){
          let destinos = this.idDestino.map(a =>a.value.toString().toLocaleUpperCase())
          if(destinos.length>0){
            //result = result.filter((ruta) => ruta.idDestino === this.idDestino.value )
            result = result.filter((ruta) => destinos.includes(ruta.idDestino) )
          }
        }        
        if(this.producto){          
          let productos = this.producto.map(a =>a.value.toString().toLocaleUpperCase())
          if(productos.length>0){
            result = result.filter((ruta) => productos.includes(ruta.producto.toString().toLocaleUpperCase()) )
          }
        }
        if(this.saltos){
          result = result.filter((ruta) => ruta.saltos.toString() === this.saltos )
        }
        if(this.range){
          result = result.filter((ruta) => ruta.costoTramo >= this.range[0] && ruta.costoTramo <= this.range[1])
        }
        if(this.rangeVol){
          result = result.filter((ruta) => ruta.volumen >= this.rangeVol[0] && ruta.volumen <= this.rangeVol[1])
        }
        if(this.rangeTonKm){
          result = result.filter((ruta) => ruta.costoTramoKm >= this.rangeTonKm[0] && ruta.costoTramoKm <= this.rangeTonKm[1])
        }
        return result
      }
      else {
        return []
      }
    },    
  }
}
</script>

<style scoped lang="scss">
/* tr:nth-child(odd)   { background-color: #eee; }
tr:nth-child(even)    { background-color:#fff; } */

/* .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) { 
  background: #B1B8BF;
  color: white;
}

.v-data-table::v-deep th {
  font-size: 0.9rem !important;
  font-weight: 700;
}
.v-data-table::v-deep td {
  font-size: 0.85rem !important;
  font-weight: 400;  
}*/

.flexcard {
  display: flex;
  flex-direction: column;
}
// Add the css below if your card has a toolbar, and if your toolbar's height increases according to the flex display.
.flexcard .v-toolbar {
  flex: 0;
}

.selected{
  background-color: limegreen;
}

p:not([class]) {
  max-width: 50ch;
}

.box {
  width: 100%;
  border-radius: 5px;
  align-content: center;
}

.subtitle {
  margin: 1rem;
}

.planta {
  background-color: #F02230;
  color: white;      
}

.bodega {
  background-color: #0000B4;
  color: white;
}

.tramo {
  background-color: #eee;
  color: hsl(var("--clr-light"));
  font-weight: 600;
}

.btn__mode {
  position:absolute;
  top:17px;
  right:20px;
  z-index:2;
  width: 10px;
  height:10px;
  border-radius: 50%;
}

.btn__kpi {
  width:100%;
  height:30px;
  background-color:#0000B4;
  margin: 0.5rem 0.30rem 0 0.30rem;
  color: #fff;
  align-content:center;
}

.title__resumen{
  border-bottom: 1px solid #E0E0E0;
  font-size: 0.9rem;
  font-weight: 700;  
}

.footer-line {
  width: 100%;
  align-content: center;
  background-color: #eee;
  
}
.footer-end{
  border-radius: 0 0 10px 10px;
}

.alertBox{  
  height: 50px;
  max-width: 330px;
  position: absolute;
  bottom: 5px;
  right: 15px;
  border-radius: 5px;
  color: #fff;
  padding: 5px;
  margin-left: 15px;
  align-content: center;
}

.green {
  background-color: #F02230;
}

.red {
  background-color: #900;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
  min-width: 100%;
}
th, td {
  padding: 0.25rem;
  text-align: center;
  white-space: nowrap;
  color: #000;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #eee;
}
tr th {
  background: white;
  color: black;
}
th {
  background: white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
</style>
<style>
body {
  overscroll-behavior-y: contain;
}
</style>
